import TomSelect from "tom-select";

let $ = require("jquery");
global.$ = global.jQuery = $;
window.$ = $;
window.jQuery = $;


import 'startbootstrap-grayscale/vendor/jquery/jquery.slim.min'
import 'startbootstrap-grayscale/vendor/jquery-easing/jquery.easing.min'
import 'startbootstrap-grayscale/vendor/bootstrap/js/bootstrap.min'
import 'bootstrap/js/dist/tooltip'
import 'startbootstrap-grayscale/js/grayscale.min'
import 'tom-select/dist/js/tom-select.base.min'



$(document).ready(function() {

    //$('input').tooltip({placement:'auto'});
    $('[data-toggle="tooltip"]').tooltip({placement:'auto'});
    document.querySelectorAll('.tagTomSelect').forEach((el)=>{
        let settings = {
            persist: false,
            createOnBlur: true,
            create: true,
            options: [
                { value: 'Belotero', text: 'Belotero' },
                { value: 'Bocouture', text: 'Bocouture' },
                { value: 'Formation', text: 'Formation' },
                { value: 'Radiesse', text: 'Radiesse' },
                { value: 'Ulthera', text: 'Ulthera' }
            ],
            render: {
                item: function (data, escape) {
                    return '<div class="bg-info text-white p-2">' + escape(data.text) + '</div>';
                },
            }
        };
        new TomSelect(el,settings);
    });
    $('.eventUnsubscribeButton').click(function (event){
        if (confirm('Êtes-vous certain-e de vous désinscrire de cet événement ?'))
            document.location.href = $(event.currentTarget).data('url');
    });
    $('select[data-controller=symfony--ux-autocomplete--autocomplete]').each(function(){
        var el = $(this);
        let settings = {
            sortField: {field: "text", direction: "asc"},
            plugins:['remove_button'],
        };
        if (el.attr('placeholder')){
            settings.placeholder=el.attr('placeholder');
        }
        if (el.attr('multiple')){
            settings.plugins =['checkbox_options', 'remove_button'];
        }
        new TomSelect(el, settings);
    });

});